import './scss/index.scss';
import * as htmx from 'htmx.org';
import * as bootstrap from 'bootstrap'
import Alpine from 'alpinejs'

import dialog from '@fylgja/alpinejs-dialog'

window.Alpine = Alpine
window.bootstrap = bootstrap;
window.htmx = htmx;

Alpine.plugin(dialog);
Alpine.start()
